import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../components/ScrollToTop";
import { Outlet } from "react-router-dom";
import CartButton from "../components/CardButton";
import AddToCartCom from "../components/AddToCartCom";
import { useSelector } from "react-redux";
import MobileNav from "../components/MobileNav";
import SideBar from "../components/SideBar";

const HomeLayout = () => {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const [sideNav, setSideNav] = useState(false);
  // const [loading, setLoading] = useState(false);
  const handleMenu = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setSideNav(() => !sideNav);
  };

  const [state, setState] = useState(false);
  const handleClose = () => {
    setSideNav(() => !sideNav);
  };

  const handleAddToCart = () => {
    setState(!state);
  };

  return (
    <>
      {sideNav && (
        <>
          <div
            onClick={handleClose}
            className="bg-black/40   z-[9999]  fixed top-0 left-0 right-0 bottom-0 w-full h-full"
          ></div>
          <div className="flex justify-center items-center min-w-full">
            <SideBar handleClose={handleClose} />
          </div>
        </>
      )}
      <div className=" bg-[#F2F3F8] ">
        <div className="min-h-screen">
          <Navbar />
          <ToastContainer />
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>

          <button
            onClick={handleAddToCart}
            className="fixed top-[45%] right-0 z-[9999]"
          >
            <CartButton data={data} cartTotalAmount={cartTotalAmount} />
          </button>
          {state && <AddToCartCom state={state} setState={setState} />}
          <MobileNav handleMenu={handleMenu} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomeLayout;
