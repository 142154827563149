import { useSelector } from "react-redux";

import CategoryCard from "../components/CategoryCard";
import SectionTitle from "../components/SectionTitle";
import { Link } from "react-router-dom";

const CategorySection = () => {
  const { items: category } = useSelector((state) => state.category);
  return (
    <div className="wrapper mt-10">
      <SectionTitle title={"ক্যাটাগরি"} />
      <div className="flex flex-wrap justify-center items-center gap-5">
        {category.slice(0, 4).map((data) => (
          <CategoryCard key={data.id} data={data} />
        ))}
      </div>
      <Link
        className="text-center text-xl font-semibold underline underline-offset-2 hover:text-[#585858] duration-300  inline-block w-full mt-3"
        to={"/categories"}
      >
        View All
      </Link>
    </div>
  );
};

export default CategorySection;
