import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const { items: logo } = useSelector((state) => state.logo);
  return (
    <div className="bg-black z-[9999] py-5 sticky top-0">
      <div className="container mx-auto flex  items-center justify-between">
        <Link
          to={"/"}
          className="brand flex items-center justify-center w-full text-2xl italic font-semibold"
        >
          <div className="w-[8rem] h-[3rem] lg:w-[10rem] lg:h-[5rem]">
            <img
              src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo.avatar}`}
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LandingNavbar;
