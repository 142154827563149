import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";

import AddToCart from "./pages/AddToCart";
import TermsConditions from "./pages/TermsConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import SupportPolicy from "./pages/SupportPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Checkout from "./pages/Checkout";
import { useSelector } from "react-redux";
import SuccessPage from "./pages/SuccessPage";

import "react-toastify/dist/ReactToastify.css";

import About from "./pages/About";
import Contact from "./pages/Contact";
import SellCondition from "./pages/SellCondition";

import BlogPage from "./pages/BlogPage";
import CategoryPage from "./pages/CategoryPage";

import SubCategoryPage from "./pages/SubCategoryPage";

import LandingPage from "./pages/landingPage";
import NotFound from "./pages/NotFound";
import HomeLayout from "./layout/HomeLayout";
import LandingLayout from "./layout/LandingLayout";
import AllCategory from "./pages/AllCategory";

function App() {
  const { cartItems: data } = useSelector((state) => state.cart);

  // const [loading, setLoading] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/productdetails/:id",
          element: <ProductDetails />,
        },
        {
          path: "/addtocart",
          element: <AddToCart />,
        },
        {
          path: "/checkout",
          element: data.length > 0 ? <Checkout /> : <NotFound />,
        },
        {
          path: "/successpage",
          element: <SuccessPage />,
        },
        {
          path: "/terms",
          element: <TermsConditions />,
        },
        {
          path: "/returnpolicy",
          element: <ReturnPolicy />,
        },
        {
          path: "/supportpolicy",
          element: <SupportPolicy />,
        },
        {
          path: "/privacypolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/about-us/:id",
          element: <About />,
        },
        {
          path: "/contact-us/:id",
          element: <Contact />,
        },
        {
          path: "/sellcondition",
          element: <SellCondition />,
        },
        {
          path: "/blogs/:id",
          element: <BlogPage />,
        },
        {
          path: "/categories",
          element: <AllCategory />,
        },
        {
          path: "/categorypage/category/:id",
          element: <CategoryPage />,
        },
        {
          path: "/subcategory/:id",
          element: <SubCategoryPage />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/landingpage",
      element: <LandingLayout />,
      children: [
        {
          path: "/landingpage/:id",
          element: <LandingPage />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
