import { Link } from "react-router-dom";

const CategoryCard = ({ data }) => {
  return (
    <Link
      to={`/categorypage/category/${data.id}`}
      className=" w-[19rem]   rounded-lg overflow-hidden"
    >
      <div className="w-full h-60 overflow-hidden">
        <img
          src={`${process.env.REACT_APP_URL}/uploads/category/${data?.icon}`}
          alt=""
          className="w-full h-full object-fill"
        />
      </div>
      <h3 className="text-xl tracking-widest p-2">{data.name}</h3>
    </Link>
  );
};

export default CategoryCard;
