import React from "react";
import { useSelector } from "react-redux";
import CategoryCard from "../components/CategoryCard";

const AllCategory = () => {
  const { items: category } = useSelector((state) => state.category);
  return (
    <div className="pt-36 flex flex-wrap justify-center items-center gap-5">
      {category.map((data) => (
        <CategoryCard key={data.id} data={data} />
      ))}
    </div>
  );
};

export default AllCategory;
