import { useEffect, useState } from "react";

// import SectionTitle from "../components/SectionTitle";
import { useParams } from "react-router-dom";
import Card from "../components/Card";

const CategoryPage = () => {
  const [categoryProduct, setCategoryProduct] = useState([]);

  // const [subCategory, setSubCategory] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/${id}`
        );
        const data = await res.json();
        setCategoryProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(
  //         `${process.env.REACT_APP_URL}/subcategorycollection/${id}`
  //       );
  //       const data = await res.json();
  //       setSubCategory(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchData();
  // }, [id]);
  return (
    <div className="px-2 pt-36  container mx-auto min-h-screen">
      {/* <span className="flex items-center justify-center">
        <SectionTitle title={"Category Products"} />
      </span> */}
      <div className="flex flex-wrap justify-center items-center  gap-3">
        {categoryProduct.length > 0 ? (
          categoryProduct?.map((product) => (
            <div key={product.id}>
              <div className="overflow-hidden">
                <Card product={product} />
              </div>
            </div>
          ))
        ) : (
          <div className="  text-center">
            <span> No Product Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
