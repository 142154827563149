import React from "react";

import { BsCart, BsChatRightDotsFill, BsTelephone } from "react-icons/bs";
import { TbCategory } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MobileNav = ({ handleMenu }) => {
  const { items: logo } = useSelector((state) => state.logo);

  const { cartItems: product } = useSelector((state) => state.cart);

  return (
    <>
      <div className="w-full h-16 bg-black fixed bottom-0 left-0 right-0 block md:hidden z-[9990]">
        <div className="grid grid-cols-5 h-full w-full gap-2 items-center justify-between text-white">
          <div className="w-full h-full col-span-1 flex items-center justify-center">
            <button onClick={handleMenu} className="">
              <TbCategory className="text-3xl" />
            </button>
          </div>
          <div className="h-full w-full col-span-1 flex items-center justify-center">
            <Link to="/addtocart" className=" block md:hidden relative group">
              <BsCart className="text-3xl" />
              <span className=" absolute bottom-5 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                {product.length}
              </span>
            </Link>
          </div>
          <div className="w-16 h-full col-span-1 flex items-center justify-center">
            <Link
              to={"/"}
              className="brand  w-full h-full text-base md:text-2xl italic font-semibold rounded-md flex items-center justify-center"
            >
              <div className="w-10 h-10 rounded-full overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
            </Link>
          </div>
          <div className="w-full h-full col-span-1 flex items-center justify-center">
            <Link to={"https://wa.me/+8801979791351"}>
              <BsTelephone className="text-3xl" />
            </Link>
          </div>
          <div className="w-full h-full col-span-1 flex items-center justify-center">
            <Link
              // to={`https://m.me/774828232889725`}
              target="_blank"
              rel="noreferrer"
            >
              <BsChatRightDotsFill className="text-3xl" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
