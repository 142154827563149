import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.slider);

  return (
    <div className="banner-slider-frame  pt-32">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={700}
        hashNavigation
        loop={true}
        modules={[Autoplay, Navigation, HashNavigation]}
        className="mySwiper h-full"
      >
        {data.map((image) => (
          <div key={image.id}>
            <SwiperSlide>
              <div className="w-full h-[18rem] md:h-[40rem] overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_URL}/public/uploads/slider/${image?.slider}`}
                  alt={image.alt}
                  className={` h-full  w-full  transition-opacity duration-500 object-cover md:object-fill object-center `}
                />
              </div>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlider;
