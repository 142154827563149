import { useRef } from "react";
import { Link, NavLink } from "react-router-dom";

// import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";

import { useSelector } from "react-redux";
import Search from "./search/Search";

const Navbar = ({ handleMenu }) => {
  // const [searchItem, setSearchItem] = useState("");

  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);
  const { items: logo } = useSelector((state) => state.logo);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#121232" : null,
      fontWeight: isActive ? "bold" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const handleSearch = (e) => {
    e.preventDefault();

    inputField.current.blur();
  };

  // const { items: landingPageData } = useSelector((state) => state.landingPage);

  return (
    <div className="fixed px-4 md:px-0 top-0 left-0  right-0 z-[9998] bg-white ">
      {/* middle nav */}
      <div className=" py-5">
        <div className="container mx-auto flex  items-center justify-between">
          <button className="md:hidden block duration-700 text-[#331b08]">
            <FiMenu onClick={handleMenu} className="text-[28px]  " />
          </button>
          <Link
            to={"/"}
            className="brand inline-block text-2xl italic font-semibold rounded-md overflow-hidden 2xl:px-0 xl:px-14"
          >
            <div className="w-[10rem] h-12">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo.avatar}`}
                className="w-full h-full "
                alt=""
              />

              {/* <img
                  src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo.avatar}`}
                  className="w-full h-full object-cover"
                  alt=""
                /> */}
            </div>
          </Link>
          {/* search button */}
          {/* search button */}
          <div className="hidden md:block">
            <form
              onSubmit={handleSearch}
              className=" flex items-center justify-center"
            >
              <Search />
            </form>
          </div>
          <div className="hidden md:block 2xl:px-0 xl:px-14">
            <div className=" text-slate-400  text-[0.9rem] flex items-center gap-2 duration-1000">
              {/* <Link
                  to={`${process.env.REACT_APP_URL}/user/home`}
                  target="_blank"
                  rel="noreferrer"
                  className=" hover:underline underline-offset-2 duration-300 "
                >
                  Login
                </Link> */}
              {/* <span className="text-slate-400/40">or</span>
                <Link className=" hover:underline underline-offset-2 duration-300 ">
                  Registration
                </Link> */}

              <Link to="/addtocart" className="relative group px-5">
                <BsCart3 className="text-3xl text-black" />
                <span className=" absolute -top-5  text-sm bottom-2 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full text-black group-hover:bg-sky-500 duration-300">
                  {product.length}
                </span>
              </Link>
            </div>
          </div>

          <Link to="/addtocart" className=" block md:hidden relative group ">
            <BsCart3 className="" />
            <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
              {product.length}
            </span>
          </Link>
        </div>
      </div>
      {/* lower nav */}
      <div className={`bg-[#2658aa] hidden md:block`}>
        <div className="container mx-auto">
          <div className="flex justify-center">
            <ul className="flex text-[0.9rem] text-white  lg:text-[1rem] gap-5 py-2 justify-between items-center">
              <li>
                <NavLink
                  style={navActive}
                  end
                  to={"/"}
                  className="hover:text-gray-300 duration-300"
                >
                  Home
                </NavLink>
              </li>
              {data?.slice(0, 6).map((navItem) => (
                <li key={navItem.id}>
                  <NavLink
                    className="hover:text-gray-300 duration-300"
                    style={navActive}
                    to={`/categorypage/category/${navItem.id}`}
                    key={navItem.id}
                  >
                    {navItem.name}
                  </NavLink>
                </li>
              ))}
              <span className="">
                {/* {landingPageData?.map((pageData) => (
                    <Link to={`/landingpage/${pageData.id}`} key={pageData.id}>
                      Landing page {pageData.id}
                    </Link>
                  ))} */}
              </span>
            </ul>
          </div>
        </div>
      </div>
      {/* <div
          className={`bg-slate-600   ${
            toggle
              ? " absolute  left-[0rem] top-[5rem] ease-in-out duration-[500ms]"
              : " absolute  left-[-100rem] top-[5rem] ease-in-out duration-[500ms] "
          } md:hidden  w-[20rem] min-h-screen    z-[9999]  right-0 bottom-0 rounded-b-sm `}
        >
          <ul className=" flex flex-col mt-24  justify-center items-center gap-4 p-7">
            <li
              onClick={handleClose}
              className=" w-full flex justify-center items-center"
            >
              <NavLink
                end
                to={"/"}
                className=" w-full flex justify-center text-[#212121] duration-300 hover:text-[#D89307]"
              >
                Home
              </NavLink>
            </li>
            {data?.slice(0, 6).map((navItem) => (
              <li onClick={handleClose}>
                <NavLink
                  style={navActive}
                  to={`/categorypage/${navItem.id}`}
                  key={navItem.id}
                  className="text-slate-700 hover:text-slate-500 duration-300"
                >
                  {navItem.name}
                </NavLink>
              </li>
            ))}

            <li className=" text-slate-400  text-[0.9rem] flex items-center gap-2 duration-1000">
              <Link className=" hover:underline underline-offset-2 duration-300">
                Login
              </Link>
              <span className="text-slate-400/40">or</span>
              <Link className=" hover:underline underline-offset-2 duration-300 ">
                Registration
              </Link>

              <li onClick={handleClose}>
                <Link to="/addtocart" className=" relative group">
                  <BsCart3 />
                  <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                    {product.length}
                  </span>
                </Link>
              </li>
            </li>
          </ul>
        </div> */}
    </div>
  );
};

export default Navbar;
