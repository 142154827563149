import { useState } from "react";
import { BsCartCheck } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { currencyFormatter } from "../utils/currencyFormatter";

const FeaturedCard = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartQuantity] = useState(1);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(
      addToSingleCart({
        ...product,
        cartQuantity,
        variantPrice: product?.unit_price,
      })
    );
    navigate("/checkout");
  };
  return (
    <div className="flex flex-col w-full bg-white border-2 group-hover:border-[#C3161C] rounded-xl duration-300">
      <Link to={`/productdetails/${product?.id}`}>
        <div className="image-section p-2">
          <div className="h-[10rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div className="product-details flex flex-col gap-2  p-2 ">
          <div
            to={`/productdetails/${product.id}`}
            className="font-semibold cursor-pointer hover:text-[#9e1212] truncate duration-300 "
          >
            {product?.name}
          </div>
          <span className="text-base  flex gap-2">
            <span className="font-semibold text-[#e25252] text-xl">
              {currencyFormatter(product?.unit_price)}
            </span>
          </span>
        </div>
      </Link>

      <div className="w-full  flex items-center justify-center rounded-md bg-black group-hover:bg-white duration-300">
        <button
          onClick={handleBuyNow}
          className="py-2 px-2 flex justify-center items-center gap-1  md:gap-2 text-white  md:text-base font-normal md:font-medium"
        >
          <div className="flex justify-center gap-2">
            <span className="flex justify-center items-center">
              <BsCartCheck />
            </span>
            <span className="text-[12px] md:text-base flex justify-center items-center">
              অর্ডার করুন
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default FeaturedCard;
