import React from "react";
import SectionTitle from "../components/SectionTitle";

const SupportPolicy = () => {
  return (
    <div className="container mx-auto px-2">
      <SectionTitle title={"Support Policy"} />
    </div>
  );
};

export default SupportPolicy;
